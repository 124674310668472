// CUSTOM HOOK COUSE, YEA, I'M A HOOKER
// https://stackoverflow.com/questions/63936267/how-to-extract-and-play-animation-in-react-three-fiber
// struggling with animation in react-three-fiber and Drei, thi is the tricky solution

import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const useAnimationPart = (animations, model) => {
  let mixer;
  if (animations.length) {
    mixer = new THREE.AnimationMixer(model);
    animations.forEach((clip) => {
      const action = mixer.clipAction(clip);
      action.play();
    });
  }

  useFrame((state, delta) => {
    mixer?.update(delta);
  });
};

export default useAnimationPart;
