const Floor = (props) => {
  return (
    <mesh
      {...props}
      recieveShadow
      position={[0, -1, 0]}
      rotation={[-Math.PI / 2, 0, 0]}
    >
      <circleGeometry args={[2, 64, 0.5]} />
      <meshPhysicalMaterial color="lightgray" transparent={true} />
    </mesh>
  );
};

export default Floor;
