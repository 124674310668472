import { useEffect, useState } from "react";
import Dropdown from "../template/dropdown/Dropdown";
import Swap from "../template/swap/Swap";
import PropTypes from "prop-types";

const Controls = ({ setIsPlaying, setAnimationUrl }) => {
  const [currentState, setCurrentState] = useState(false);

  useEffect(() => {
    setIsPlaying(currentState);
  }, [currentState]);

  return (
    <>
      <Swap
        active={{
          value: true,
          label: (
            <>
              <img src={"/icons/hand.svg"} alt="Hand" />
            </>
          ),
        }}
        inactive={{
          value: false,
          label: (
            <>
              <img height={20} width={24} src={"/icons/play.svg"} alt="Play" />
            </>
          ),
        }}
        currentState={currentState}
        //Current Initial State
        callback={(value) => {
          setCurrentState(value);
        }}
      />
      <Dropdown
        menu={[
          { value: "/walk.glb", label: "Walk" },
          { value: "/run.glb", label: "Run" },
          { value: "/jump.glb", label: "Jump" },
          { value: "/dance.glb", label: "Dance" },
          { value: "/wave.glb", label: "Wave" },
          { value: "/thumbs.glb", label: "Thumbs" },
          { value: "/clap.glb", label: "Clap" },
          { value: "/cheer.glb", label: "Cheer" },
          // { value: "/combat.glb", label: "Combat" },
          { value: "/tpose.glb", label: "Tpose" },
        ]}
        callback={(value) => {
          setAnimationUrl(value);
          setCurrentState(false);
        }}
      />
    </>
  );
};

Controls.propTypes = {
  setIsPlaying: PropTypes.func.isRequired,
  setAnimationUrl: PropTypes.func.isRequired,
};

export default Controls;
