import PropTypes from "prop-types";

const ErrorLayout = (props) => {
  const { errorMsg } = props;
  return (
    <div>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{errorMsg}</i>
      </p>
    </div>
  );
};

ErrorLayout.propTypes = {
  errorMsg: PropTypes.string,
};

export default ErrorLayout;
