import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import ErrorLayout from "../components/app/Error";
import LoadingLayout from "../components/app/Loading";
import AvatarView from "../components/avatarView/AvatarView";
import useGetNftMetadata from "../hooks/useGetNftMetadata";

export default function Avatar3DViewer() {
  const { contractAddress: contract, tokenId: token } = useParams();
  const [searchParams] = useSearchParams();

  const [contractAddress, setContractAddress] = useState(contract);
  const [tokenId, setTokenId] = useState(token);

  useEffect(() => {
    if (searchParams.has("contractAddress")) {
      setContractAddress(searchParams.get("contractAddress"));
    }

    if (searchParams.has("tokenId")) {
      setTokenId(searchParams.get("tokenId"));
    }
  }, [searchParams]);

  const { nftMetadata, currentError } = useGetNftMetadata(
    contractAddress,
    tokenId
  );

  if (currentError) {
    return <ErrorLayout errorMsg={currentError} />;
  }

  return (
    <>
      {nftMetadata?.glb_url ? (
        <AvatarView avatarLink={nftMetadata?.glb_url} />
      ) : (
        <LoadingLayout />
      )}
    </>
  );
}
