import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import "./style.css";

const Swap = ({ active, inactive, callback, currentState }) => {
  const [isActived, setIsActived] = useState(currentState);
  const swap = useRef();
  const container = useRef();
  const activeElem = useRef();
  const inactiveElem = useRef();

  const handleClick = () => {
    setIsActived(!isActived);
  };

  useEffect(() => {
    setIsActived(currentState);
  }, [currentState]);

  useEffect(() => {
    if (isActived) {
      swap.current.focus();
      swap.current.style.height = "100%";
    } else {
      swap.current.style.height = "56px";
      swap.current.style.overflow = "hidden";
      swap.current.blur();
    }
  }, [isActived]);

  useEffect(() => {
    swap.current.checked = isActived;

    if (isActived) {
      activeElem.current.style.transform = "rotateY(0deg)";
      activeElem.current.style.opacity = "1";
      inactiveElem.current.style.transform = "rotateY(180deg)";
      inactiveElem.current.style.opacity = "0";
    } else {
      activeElem.current.style.transform = "rotateY(-180deg)";
      activeElem.current.style.opacity = "0";
      inactiveElem.current.style.transform = "rotateY(0deg)";
      inactiveElem.current.style.opacity = "1";
    }

    callback(isActived);
  }, [isActived]);

  return (
    <div className="swap">
      <label ref={container}>
        <input type="checkbox" ref={swap} />

        <div onClick={handleClick} className="active" ref={activeElem}>
          {active?.label}
        </div>
        <div onClick={handleClick} className="inactive" ref={inactiveElem}>
          {inactive?.label}
        </div>
      </label>
    </div>
  );
};

export default Swap;

Swap.propTypes = {
  active: PropTypes.shape({ value: PropTypes.string, label: PropTypes.any }),
  inactive: PropTypes.shape({ value: PropTypes.string, label: PropTypes.any }),
  callback: PropTypes.func,
  currentState: PropTypes.bool,
};
