import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import "./style.css";

const Dropdown = ({ menu, callback }) => {
  const [currentValue, setCurrentValue] = useState(menu[0]?.value);
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = useRef();

  const handleClick = (value) => {
    setIsOpen(!isOpen);

    setCurrentValue(value);
  };

  useEffect(() => {
    if (isOpen) {
      dropdown.current.focus();

      const totalHeight = Array.from(dropdown.current.children).reduce(
        (acc, item) => {
          return acc + item.offsetHeight;
        },
        10
      );

      dropdown.current.style.height = totalHeight + "px";

      dropdown.current.style.maxHeight = window.innerHeight - 50 + "px";

      if (window.innerHeight < totalHeight) {
        dropdown.current.style.overflow = "auto";
      }
    } else {
      dropdown.current.style.height = "56px";
      dropdown.current.style.overflow = "hidden";
      dropdown.current.scrollTop = 0;

      dropdown.current.blur();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!callback) return;
    callback(currentValue);
  }, [currentValue]);

  return (
    <div>
      <ul tabIndex={0} className="dropdownMenu" ref={dropdown}>
        {currentValue && (
          <li
            key={currentValue}
            className="selected"
            onClick={() => handleClick(currentValue)}
          >
            {menu?.find((item) => item.value === currentValue)?.label}
          </li>
        )}

        {menu?.map((item) => {
          const { value, label } = item;
          if (value === currentValue) {
            return (
              <li key={value + "active"} className="active">
                {label}
              </li>
            );
          }

          return (
            <li key={value} onClick={() => handleClick(value)}>
              {label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  callback: PropTypes.func,
};
