import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import ErrorLayout from "./components/app/Error";
import Avatar3DViewer from "./pages/Avatar3DViewer";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Avatar3DViewer />,
      errorElement: <ErrorLayout errorMsg={"Router error"} />,
    },
    {
      path: "/:contractAddress/:tokenId",
      element: <Avatar3DViewer />,
      errorElement: <ErrorLayout errorMsg={"Router error"} />,
    },
  ]);

  return <RouterProvider router={router} />;
}
export default App;
