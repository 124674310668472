import { Environment, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useState } from "react";
import Controls from "./Controls";
import Floor from "./Floor";
import Model from "./Model";
import PropTypes from "prop-types";

const AvatarView = ({ avatarLink }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [animationUrl, setAnimationUrl] = useState(
    "https://union-web-asset.s3.eu-central-1.amazonaws.com/avatarPose.glb"
  );

  return (
    <div className="canvas-container">
      <Controls setIsPlaying={setIsPlaying} setAnimationUrl={setAnimationUrl} />

      <Canvas
        shadows
        className="canvas"
        camera={{
          position: [-1, 6, 14],
          zoom: 7,
        }}
      >
        <ambientLight color={"white"} intensity={0.22} />

        <OrbitControls
          makeDefault
          maxPolarAngle={Math.PI / 2.2}
          minPolarAngle={Math.PI / 8}
          zoomSpeed={0.5}
          maxDistance={20}
          minDistance={5}
          panSpeed={0.07}
          enablePan={true}
          autoRotate={isPlaying ? false : true}
        />
        <Model
          position={[0, -0.84, 0]}
          avatarLink={avatarLink}
          animationUrl={animationUrl}
          isPlaying={isPlaying}
        />
        <Environment preset="lobby" background blur={0.5} />
        <Floor />
      </Canvas>
    </div>
  );
};

AvatarView.propTypes = {
  avatarLink: PropTypes.string.isRequired,
};

export default AvatarView;
