// this hoks get the query params and return the nft usefull metadata.

import axios from "axios";
import { useMemo, useState } from "react";
import { tokenUriAbi } from "../logic/abi";
import { client } from "../logic/client";

const useGetNftMetadata = (contractAddress, tokenId) => {
  const [nftMetadata, setNftMetadata] = useState(null);
  const [currentError, setCurrentError] = useState(null);

  useMemo(async () => {
    if (!contractAddress) return;
    const url = await client
      .readContract({
        address: contractAddress,
        abi: [tokenUriAbi],
        functionName: "tokenURI",
        args: [tokenId],
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        setCurrentError(err.message);
      });

    if (!url) return;

    axios
      .get(url, {
        httpsAgent: true,
      })
      .then((res) => {
        setNftMetadata(res.data);
        setCurrentError(null);
      })
      .catch((err) => {
        setCurrentError(err.message);
      });

    return url || null;
  }, [contractAddress, tokenId]);

  if (!contractAddress || !tokenId)
    return {
      nftMetadata: null,
      currentError: `Query params 'contractAddress' and/or 'tokenId' are not provided.`,
    };

  return {
    nftMetadata,
    // : {
    //   ...nftMetadata,
    //   glb_url: nftMetadata?.glb_url?.replace(
    //     "https://nft-vrm-test.s3.eu-central-1.amazonaws.com/",
    //     "/fallback/"
    //   ),
    // },

    currentError,
  };
};

export default useGetNftMetadata;
