import { useGLTF } from "@react-three/drei";
import { useEffect, useState } from "react";
import useAnimationPart from "../../hooks/useGetAnimations";
import PropTypes from "prop-types";

const Model = (props) => {
  const {
    avatarLink: avatarLinkProp,
    animationUrl: animationUrlProp,
    isPlaying,
  } = props;
  const [avatarLink, setAvatarLink] = useState(avatarLinkProp);
  const [animationUrl, setAnimationUrl] = useState(animationUrlProp);
  const { scene: model } = useGLTF(avatarLink);
  const { animations } = useGLTF(animationUrl);
  useAnimationPart(animations, model, isPlaying);

  useEffect(() => {
    setAvatarLink(avatarLinkProp);
  }, [avatarLinkProp]);
  useEffect(() => {
    setAnimationUrl(animationUrlProp);
  }, [animationUrlProp]);

  useEffect(() => {
    if (isPlaying) {
      setAnimationUrl(
        "https://union-web-asset.s3.eu-central-1.amazonaws.com/avatarPose.glb"
      );
    } else {
      setAnimationUrl(animationUrlProp);
    }
  }, [isPlaying]);

  return <primitive object={model} scale={1} {...props} />;
};

Model.propTypes = {
  avatarLink: PropTypes.string.isRequired,
  animationUrl: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool.isRequired,
};

export default Model;
